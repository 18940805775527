import { useMemo } from 'react';

import { useGetPerformanceLoginContent } from '../contentstack';

import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Variables {
  contentOptions: ContentOptions;
}

export interface PerformanceLoginContent {
  birthDateInputLabel: string;
  ctaText: string;
  dobRequiredValidation: string;
  driversLicenseInputLabel: string;
  driversLicensePlaceholder: string;
  headerText: string;
  helpDisclosure: string;
  idRequiredValidation: string;
  imageUrl: string;
  incorrectSsnValidation: string;
  isDateValidation: string;
  isFutureDateDisabledValidation: string;
  maxValidation: string;
  passportLabel: string;
  passportPlaceholder: string;
  ssnInputLabel: string;
  ssnPlaceholder: string;
  ssnRequiredValidation: string;
  termsDisclosure: string;
}

export const useGetPerformanceLoginData = ({ contentOptions }: Variables): AsyncResult<PerformanceLoginContent> => {
  const { data: contentData, loading: contentLoading, error: contentError } = useGetPerformanceLoginContent({
    variables: contentOptions,
  });

  const content = contentData?.all_performance_login?.items?.[0];
  const validationMessages = contentData?.all_performance_login?.items?.[0]?.validation_messages;
  const getValuesByKey = (key: string, array: any[]) =>
    array?.find((val: { key: string }) => val.key === key)?.value ?? '';

  const transformedContent = {
    headerText: content?.header_text ?? '',
    imageUrl: content?.header_imageConnection?.edges?.[0]?.node?.url ?? '',
    birthDateInputLabel: getValuesByKey('birthdate', content?.input_field_labels?.input_field?.value),
    dobRequiredValidation: validationMessages?.find(val => val?.key === 'dobRequired')?.message ?? '',
    driversLicenseInputLabel: getValuesByKey('drivers_license', content?.input_field_labels?.input_field?.value),
    idRequiredValidation: validationMessages?.find(val => val?.key === 'idRequired')?.message ?? '',
    incorrectSsnValidation: validationMessages?.find(val => val?.key === 'incorrectSsn')?.message ?? '',
    isDateValidation: validationMessages?.find(val => val?.key === 'isDate')?.message ?? '',
    isFutureDateDisabledValidation: validationMessages?.find(val => val?.key === 'isFutureDateDisabled')?.message ?? '',
    ssnInputLabel: getValuesByKey('ssn', content?.input_field_labels?.input_field?.value),
    passportLabel: getValuesByKey('passport', content?.input_field_labels?.input_field?.value),
    driversLicensePlaceholder: getValuesByKey('drivers_license', content?.placeholder_text?.placeholder?.value),
    maxValidation: validationMessages?.find(val => val?.key === 'max')?.message ?? '',
    passportPlaceholder: getValuesByKey('passport', content?.placeholder_text?.placeholder?.value),
    ssnRequiredValidation: validationMessages?.find(val => val?.key === 'ssnRequired')?.message ?? '',
    ssnPlaceholder: getValuesByKey('ssn', content?.placeholder_text?.placeholder?.value),
    ctaText: content?.primary_cta ?? '',
    helpDisclosure: content?.disclosures?.find(val => val?.key === 'contact')?.rte_content_value ?? '',
    termsDisclosure: content?.disclosures?.find(val => val?.key === 'terms_and_policies')?.rte_content_value ?? '',
  };

  return useMemo(
    (): AsyncResult<PerformanceLoginContent> => ({
      data: transformedContent,
      error: contentError,
      loading: contentLoading,
    }),
    [contentData, contentError, contentLoading],
  );
};
