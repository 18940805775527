import { getApolloClient, InMemoryCache } from '~/utils/apollo-client';
import { getContentstackHttpLinkConfig } from '~/utils/contentstack';
import { getSymphonyPath } from '~/utils/routes';
import { cacheTypePolicies as symphonyCacheTypePolicies, getSymphonyHttpLinkConfig } from '~/utils/symphony';

export interface ContentstackConfig {
  deliveryToken: string;
  environment: string;
}

export const getKbaApolloClient = ({
  contentstackConfig,
  jwt,
  onLogout,
}: {
  contentstackConfig: ContentstackConfig;
  jwt: string;
  onLogout: () => void;
}) => {
  const symphonyUrl = `${getSymphonyPath()}/ui/graphql`;

  return getApolloClient({
    links: [
      getContentstackHttpLinkConfig(contentstackConfig.environment, contentstackConfig.deliveryToken),
      getSymphonyHttpLinkConfig(symphonyUrl, jwt, onLogout),
    ],
    cache: new InMemoryCache({
      typePolicies: {
        ...symphonyCacheTypePolicies,
      },
    }),
  });
};
