import { ApolloProvider } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { isAfter, isValid, startOfDay } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useGetPerformanceLoginData } from './hooks/useGetPerformanceLoginData';
import { ContentstackConfig, getKbaApolloClient } from './utils';

import { PerformanceMethodTypes } from '~/__generated__';
import { Style } from '~/components/pdfs/styles';
import { Alert } from '~/components/ui/Alert';
import { CircularProgress } from '~/components/ui/CircularProgress';
import { DatePicker } from '~/components/ui/DatePicker';
import { Box, Button, Collapse, FormControl, Grid } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';
import { AccountPerformance } from '~/containers/AccountPerformance';
import { MeasurementName } from '~/hooks/performance/types';
import { isStringEmpty } from '~/utils/client';
import { ContentOptions } from '~/utils/contentstack/src/types';
import { formatDate } from '~/utils/format/date';
import { getSymphonyPath } from '~/utils/routes';

enum InputNames {
  birthDate = 'birthDate',
  driversLicense = 'driversLicense',
  passport = 'passport',
  ssn = 'ssn',
}

type FormData = {
  birthDate: Date | null;
  driversLicense?: string;
  passport?: string;
  ssn: string;
};

type LoginQuestion = {
  label: string;
  questionId: string;
};

export interface Props {
  clientId: string;
  contentOptions: ContentOptions;
  contentstackConfig: ContentstackConfig;
  dataQa?: string;
  onJwtUpdate?: (jwt: string) => void;
  originalHost: string;
  partnerId: string;
  partnerStyles: Style;
  partyId: string;
  sessionJwt?: string;
  showPassportField?: boolean;
  staticJwt: string;
}

const PerformanceLogin: React.FC<Props> = ({
  clientId,
  contentOptions,
  contentstackConfig,
  dataQa = 'performance-login',
  onJwtUpdate,
  originalHost,
  partnerId,
  partnerStyles,
  partyId,
  sessionJwt,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [authId, sethAuthId] = useState('');
  const [loginQuestions, setLoginQuestions] = useState<LoginQuestion[]>();
  const [authenticationError, setAuthenticationError] = useState('');
  const [questionsLoading, setQuestionsLoading] = useState(true);
  const { data, loading: dataLoading, error: dataError } = useGetPerformanceLoginData({ contentOptions });
  const apolloClient = getKbaApolloClient({
    contentstackConfig,
    jwt: sessionJwt ?? '',
    onLogout: () => undefined,
  });

  const content = data;

  const formSchema = yup.object().shape({
    ssn: yup
      .string()
      .required(content?.ssnRequiredValidation ?? '')
      .matches(/^\d{4}$/, content?.maxValidation ?? ''),
    birthDate: yup
      .date()
      .nullable()
      .required(content?.dobRequiredValidation ?? '')
      .test('is-valid-date', content?.isDateValidation ?? '', value => {
        return value instanceof Date && !isNaN(value.getTime());
      })
      .test('is-not-future', content?.isFutureDateDisabledValidation ?? '', value => {
        if (value instanceof Date && isValid(value)) {
          return !isAfter(startOfDay(value), startOfDay(new Date()));
        }
        return true;
      })
      .typeError(content?.isDateValidation ?? 'Invalid date format'),
    driversLicense: yup.string().when('$showPassportField', {
      is: false,
      then: yup.string().required(content?.idRequiredValidation ?? ''),
      otherwise: yup.string(),
    }),
    passport: yup.string().when('$showPassportField', {
      is: true,
      then: yup.string().required(content?.idRequiredValidation ?? ''),
      otherwise: yup.string(),
    }),
  });

  const showPassportField = useMemo(() => {
    return loginQuestions?.find(q => q.questionId === '3')?.label === 'kba:passport';
  }, [loginQuestions]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      ssn: '',
      birthDate: null,
      driversLicense: '',
      passport: '',
    },
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
    context: { showPassportField },
  });

  useEffect(() => {
    setQuestionsLoading(true);
    axios
      .post(
        `${getSymphonyPath()}/qpr/login/initiate`,
        {
          partyId,
          clientId,
          partnerId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Original-Host': originalHost,
          },
        },
      )
      .then(res => {
        if (res.data) {
          sethAuthId(res.data.authenticationId);
          setLoginQuestions(
            res.data.questions.sort(
              (a: LoginQuestion, b: LoginQuestion) => parseFloat(a.questionId) - parseFloat(b.questionId),
            ),
          );
        }
      })
      .catch(err => {
        console.error('Error fetching questions:', err);
      })
      .finally(() => {
        setQuestionsLoading(false);
      });
  }, [clientId, originalHost, partnerId, partyId]);

  const submitForm = async (formValues: FormData) => {
    const { ssn, birthDate } = formValues;
    const loginFormValues = {
      authenticationId: authId,
      answers: [
        { questionId: '1', value: ssn },
        {
          questionId: '2',
          value: formatDate(birthDate ?? '', 'yyyy-MM-dd', {
            locale: contentOptions.locale,
          }),
        },
        showPassportField
          ? { questionId: '3', value: formValues.passport }
          : { questionId: '4', value: formValues.driversLicense },
      ],
    };
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${getSymphonyPath()}/qpr/login/submit`, loginFormValues, {
        headers: {
          'Content-Type': 'application/json',
          'X-Original-Host': originalHost,
        },
      });
      if (response.data) {
        onJwtUpdate?.(response.data.jwtToken);
      }
    } catch (err) {
      console.error('Error submitting form:', err);
      // Handle error as needed
      setAuthenticationError('Authentication failed for the provided details!');
    }
    setIsSubmitting(false);
  };

  const error = useMemo(() => {
    return authenticationError || dataError;
  }, [authenticationError, dataError]);

  const downloadableQPRProps = {
    partnerStyles,
    partyIdFA: partyId,
  };

  const loading = useMemo(() => {
    return dataLoading || questionsLoading;
  }, [dataLoading, questionsLoading]);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box data-qa={dataQa}>
          {/* We have to use this state to determine to show the AccountPerformance in the same container as we are */}
          {/* unable to access protected route from Kba authentication as we cannot mock the Auth0 authentication in browser */}
          {sessionJwt ? (
            <>
              <Grid container justifyContent="center">
                <ApolloProvider client={apolloClient}>
                  <AccountPerformance
                    contentOptions={contentOptions}
                    downloadableQPRProps={downloadableQPRProps}
                    initialTimePeriod={MeasurementName.QTD}
                    partyId={partyId}
                    performanceMethod={PerformanceMethodTypes.FROM_END_OF_DAY_VALUES}
                  />
                </ApolloProvider>
              </Grid>
            </>
          ) : (
            <>
              <Grid container justifyContent="center">
                <Grid item xs="auto">
                  {content?.imageUrl && (
                    <Box data-qa={`${dataQa}-image`} sx={{ height: '100px', pb: 4 }}>
                      <img src={content.imageUrl} />
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Typography align="center" aria-live="polite" data-qa={`${dataQa}-heading`}>
                {content?.headerText}
              </Typography>
              <Box
                sx={{ pt: 1, pb: { xs: 0, md: 3 }, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
              >
                <FormControl sx={{ '& > *, & > .MuiFormControl-root': { mb: { xs: 2, md: 3 } } }}>
                  <Grid container justifyContent="center" spacing={4}>
                    <Grid item md={3} xs={12}>
                      <Controller
                        control={control}
                        name={InputNames.ssn}
                        render={({ ref, onChange, onBlur, value, name }) => (
                          <TextField
                            InputLabelProps={{ sx: { mb: 0.5 } }}
                            error={!!errors.ssn}
                            fullWidth
                            helperText={errors.ssn?.message}
                            id={InputNames.ssn}
                            inputRef={ref}
                            label={content?.ssnInputLabel}
                            name={name}
                            onBlur={onBlur}
                            onChange={e => {
                              const inputValue = e.target.value;
                              if ((inputValue && !/^-?\d+$/.test(inputValue)) || inputValue.length > 4) {
                                e.preventDefault(); // Allow only numbers and prevent input greater than 4 characters.
                                return;
                              }
                              return isStringEmpty(e.target.value) ? undefined : onChange(e);
                            }}
                            placeholder={content?.ssnPlaceholder}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Controller
                        control={control}
                        id={InputNames.birthDate}
                        name={InputNames.birthDate}
                        render={({ onChange, value }) => (
                          <DatePicker
                            error={!!errors.birthDate}
                            fullWidth
                            helperText={errors.birthDate?.message}
                            inputLabelProps={{ sx: { mb: 0.5 } }}
                            label={content?.birthDateInputLabel}
                            maxDate={new Date()}
                            onChange={date => onChange(date)}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {showPassportField ? (
                        <Controller
                          control={control}
                          name={InputNames.passport}
                          render={({ onChange, onBlur, value, name }) => (
                            <TextField
                              InputLabelProps={{ sx: { mb: 0.5 } }}
                              error={!!errors.passport}
                              fullWidth
                              helperText={errors.passport?.message}
                              id={InputNames.passport}
                              label={content?.passportLabel}
                              name={name}
                              onBlur={onBlur}
                              onChange={e => (isStringEmpty(e.target.value) ? undefined : onChange(e))}
                              placeholder={content?.passportPlaceholder}
                              value={value}
                            />
                          )}
                        />
                      ) : (
                        <Controller
                          control={control}
                          name={InputNames.driversLicense}
                          render={({ onChange, onBlur, value, name }) => (
                            <TextField
                              InputLabelProps={{ sx: { mb: 0.5 } }}
                              error={!!errors.driversLicense}
                              fullWidth
                              helperText={errors.driversLicense?.message}
                              id={InputNames.driversLicense}
                              label={content?.driversLicenseInputLabel}
                              name={name}
                              onBlur={onBlur}
                              onChange={e => (isStringEmpty(e.target.value) ? undefined : onChange(e))}
                              placeholder={content?.driversLicensePlaceholder}
                              value={value}
                            />
                          )}
                        />
                      )}
                    </Grid>
                  </Grid>
                </FormControl>
              </Box>

              {error && (
                <Collapse in={!!error}>
                  <Alert contentOptions={contentOptions} error={error} severity="error" sx={{ mt: 1 }} />
                </Collapse>
              )}

              <Box mt={{ xs: 1, md: 3 }} px={{ xs: 1, md: 0 }}>
                <Grid container direction={{ xs: 'column-reverse', md: 'row' }} justifyContent="center" spacing={2}>
                  <Grid item md="auto" xs={12}>
                    <Button
                      data-qa={`${dataQa}-cta-next`}
                      disabled={isSubmitting}
                      onClick={handleSubmit(submitForm)}
                      variant="contained"
                    >
                      {content?.ctaText}
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ pt: 4, display: 'flex', justifyContent: 'center' }}>
                <RteContent data={content?.helpDisclosure ?? ''} sx={{ textAlign: 'center' }} />
              </Box>
              <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
                <RteContent data={content?.termsDisclosure ?? ''} sx={{ textAlign: 'center' }} />
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default PerformanceLogin;
