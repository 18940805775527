import * as queries from './queries.gql';

import {
  GetPerformanceLoginContent,
  GetPerformanceLoginContentVariables,
} from '~/containers/PerformanceLogin/contentstack/__generated__/queries.v2';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetPerformanceLoginContent = (
  options?: QueryHookOptions<GetPerformanceLoginContent, GetPerformanceLoginContentVariables>,
): QueryResult<GetPerformanceLoginContent, GetPerformanceLoginContentVariables> =>
  useContentstackQuery(queries.GetPerformanceLoginContent, options);